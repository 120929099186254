<template>
  <div class="reglement-facture-all-interface">
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on }">
        <div class="block-icon" v-on="on" @click="getFacturesFiliales">
          <IconReglementFacture></IconReglementFacture>
        </div>
      </template>
      <span>Ajouter règlement</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      max-width="1500px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Nouveau règlement</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div v-if="show == true" class="loading-custom-reglement-block">
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#704ad1'"
              class="loeder"
            />
          </div>
          <v-form ref="formRegl" lazy-validation>
            <v-row class="mt-2">
              <v-col col xs="2" sm="2" md="2">
                <v-select
                  placeholder=""
                  :items="computedTypePartenaire"
                  label="Type Partenaire"
                  v-model="reglementToAdd.type_partenaire"
                  outlined
                  :persistent-placeholder="true"
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
                  disabled
                >
                </v-select>
              </v-col>
              <v-col col xs="2.5" sm="2.5" md="2.5">
                <v-text-field
                  label="Partenaire"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  :disabled="true"
                  v-model="reglementToAdd.filiale_nom"
                ></v-text-field>
              </v-col>

              <v-col col xs="2" sm="2" md="2">
                <v-select
                  placeholder=""
                  :items="computedTypeReglement"
                  label="Type paiement"
                  v-model="reglementToAdd.type_reglement"
                  outlined
                  :rules="[v => !!v || 'Type paiement obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  required
                  :persistent-placeholder="true"
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </v-col>
              <lcr
                :reglement="reglementToAdd"
                :factures="facturesReglement"
                @saveDataForLcr="saveDataForLcr"
                :resetModalLcr="computedResetModalLcr"
              />
              <v-col
                col
                xs="2.5"
                sm="2.5"
                md="2.5"
                v-if="!computedPaiementNameSelected.includes('LCR')"
              >
                <v-select
                  placeholder=""
                  required
                  :items="computedConditionPaiement"
                  label="Conditions du paiement"
                  v-model="reglementToAdd.condition_paiement"
                  outlined
                  :persistent-placeholder="true"
                  dense
                  :rules="[v => !!v || 'Conditions du paiement obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </v-col>
              <v-col
                col
                xs="2"
                sm="2"
                md="2"
                v-if="getConditionAmountNonLettre"
              >
                <v-text-field
                  label="Montant non alloué"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  :disabled="true"
                  :value="getAmountNonLettrerReglemnt | numberWithSpaces"
                ></v-text-field>
              </v-col>
              <v-col
                col
                :xs="getConditionAmountNonLettre ? '2' : '4'"
                :sm="getConditionAmountNonLettre ? '2' : '4'"
                :md="getConditionAmountNonLettre ? '2' : '4'"
              >
                <v-text-field
                  label="Montant à payer"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  :value="computedSoldeAPaye | numberWithSpaces"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col col xs="1.25" sm="1.25" md="1.25">
                <v-select
                  placeholder=""
                  :items="computedUnite"
                  label="Unité"
                  v-model="reglementToAdd.unite"
                  outlined
                  :persistent-placeholder="true"
                  dense
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </v-col>
              <v-col col xs="2.5" sm="2.5" md="2.5">
                <v-text-field
                  label="Réference"
                  type="text"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  v-model="reglementToAdd.ref"
                ></v-text-field>
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <div class="label-for-no-vuetify ">
                  <div class="custom-label">Date paiement</div>
                  <date-picker
                    id="date_paiement"
                    value-type="format"
                    format="DD-MM-YYYY"
                    v-model="reglementToAdd.date_paiement"
                    required
                    type="date"
                    class="inputDatePaiement"
                  ></date-picker>
                </div>
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <v-text-field
                  label="Reste"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  :disabled="true"
                  value="0"
                ></v-text-field>
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <v-text-field
                  label="Solde courant"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  :disabled="true"
                  :value="reglementToAdd.solde_init | numberWithSpaces"
                ></v-text-field>
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <v-text-field
                  label="Montant Echu"
                  placeholder=""
                  :persistent-placeholder="true"
                  outlined
                  dense
                  :disabled="true"
                  :value="reglementToAdd.solde_echeance | numberWithSpaces"
                ></v-text-field>
              </v-col>
            </v-row>
            <badge
              v-if="getConditionAmountNonLettre"
              class="mt-2"
              color="purple"
            />
            <v-row>
              <v-col col xs="12" sm="12" md="12">
                <v-textarea
                  outlined
                  label="Note"
                  v-model="reglementToAdd.note"
                  :persistent-placeholder="true"
                  clearable
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-tabs
              background-color="#f1edfb"
              color="#704ad1"
              class="tabs-add-reglement-fac"
              v-model="indexTab"
            >
              <v-tab active class="tab-add-reglement-fac">
                <span class="tab-text-bold">
                  {{ 'Factures à régler ' + vendeur }}
                </span>
              </v-tab>
              <v-tab-item>
                <div class="table-reglement-fac-dialog">
                  <v-data-table
                    v-model="selectedTable"
                    :headers="fields"
                    :items="facturesReglement"
                    class="table-facture"
                    hide-default-footer
                    :calculate-widths="false"
                    :fixed-header="true"
                    hide-action
                    disable-sort
                    :items-per-page="facturesReglement.length"
                    no-data-text="Il n'y a aucun enregistrement à afficher"
                  >
                    <!-- HEADERS TABLE -->
                    <template v-slot:[`header.check_all`]="{ header }">
                      <td>
                        <span v-if="header.value != 'check_all'">
                          {{ header.label }}</span
                        >
                        <div v-else>
                          <v-checkbox
                            class="checked-global"
                            v-model="checkAll"
                            @change="handleCheckAll"
                            color="#704ad1"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                          ></v-checkbox>
                        </div>
                      </td>
                    </template>
                    <!-- BODY TABLE -->
                    <template v-slot:[`item.check_all`]="{ item }">
                      <td class="custom-cell sticky-header-1">
                        <div
                          class="checkbox-class-show mx-auto"
                          :class="{ 'checkbox-class-show-checked': item.check }"
                          @click="
                            item.check = !item.check;
                            checkFacture(item);
                          "
                        >
                          <font-awesome-icon
                            icon="check"
                            v-if="item.check"
                            class="check"
                          />
                        </div>
                      </td>
                    </template>
                    <template v-slot:[`item.num`]="{ item }">
                      <td class="custom-cell">{{ item.num }}</td>
                    </template>
                    <template v-slot:[`item.total_ttc`]="{ item }">
                      <td class="custom-cell">
                        {{ item.total_ttc | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.montant_regle`]="{ item }">
                      <td class="custom-cell">
                        {{ item.montant_regle | numberWithSpaces }}
                      </td>
                    </template>

                    <template v-slot:[`item.montant_a_regle`]="{ item }">
                      <EditableInput
                        :editable="
                          reglementToAdd.montant_a_payer != null &&
                            item.check == true
                        "
                        champName="montant_a_regle"
                        :item="item"
                        :value="item.montant_a_regle"
                        type="text"
                        editableType="input"
                        :updateFunction="updateMontantRegler"
                        :defaultColor="true"
                      />
                    </template>
                    <template v-slot:[`item.reste`]="{ item }">
                      <td class="custom-cell">
                        {{ item.reste | numberWithSpaces }}
                      </td>
                    </template>
                    <template v-slot:[`item.vendeur`]="{ item }">
                      <td class="custom-cell">
                        {{ item.vendeur ? item.vendeur : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.client`]="{ item }">
                      <td class="custom-cell">
                        {{ item.client ? item.client : '-' }}
                      </td>
                    </template>
                    <template v-slot:[`item.delais_paiement`]="{ item }">
                      <td class="custom-cell">
                        {{
                          formateDateGetters(item.date_echeance)
                            ? formateDateGetters(item.date_echeance)
                            : '-'
                        }}
                      </td>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
              <v-tab class="tab-add-reglement-fac"
                ><span class="tab-text-bold">Fichiers jointes</span>
              </v-tab>
              <v-tab-item>
                <PieceJointe
                  :permission="true"
                  :item="[]"
                  :noItems="true"
                  :reglement="reglementToAdd"
                  :show="true"
                  typeVoir="addFiles"
                  @showFiles="showFiles"
                  :resetModalData="resetModalLcr"
                ></PieceJointe>
              </v-tab-item>
            </v-tabs>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleSubmitAddReglement"
            :class="{ loader: loading }"
            :loading="loading"
          >
            Valider
          </v-btn>
          <v-btn text @click="hideModal" :class="{ loader: loading }"
            >Annuler</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Lcr from '@/views/component/reglement/lcr.vue';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import ReglementFacture from '@/models/ReglementFactureRow';
import ReglementFactureRowLibre from '@/models/ReglementFactureRowLibre';
import ReglementFactureRowMaster from '@/models/ReglementFactureRowMaster';
import ReglementFactureRowOblige from '@/models/ReglementFactureRowOblige';
export default {
  props: {
    dataToUse: { required: true },
    interfaceModal: { default: 'ReglementFacture' },
    interfaceName: { required: false },
    societeRequired: { required: false }
  },
  data() {
    return {
      selectedTable: [],
      dialog: false,
      resetModalLcr: false,
      dataLcr: null,
      checkAll: false,
      reglementToAdd: {
        filiale_id: null,
        filiale_nom: null,
        type_partenaire: 'Client',
        type_reglement: null,
        condition_paiement: null,
        montant_a_payer: null,
        unite: 'EUR',
        ref: null,
        date_paiement: moment(Date.now()).format('DD-MM-YYYY'),
        reste: null,
        solde: null,
        solde_init: null,
        note: null,
        files: []
      },
      show: false,
      loading: false,
      error: null,
      vendeur: '',
      indexTab: 'tab-0',
      fields: [
        {
          value: 'check_all',
          text: '',
          width: '40px !important',
          class: 'sticky-header-1'
        },
        {
          value: 'num',
          text: 'Numéro'
        },
        {
          value: 'total_ttc',
          text: 'Montant Total'
        },
        {
          value: 'reste',
          text: 'Reste'
        },
        {
          value: 'montant_regle',
          text: 'Montant Réglé'
        },
        {
          value: 'montant_a_regle',
          text: 'Montant à Régler'
        },
        {
          value: 'vendeur',
          text: 'Société'
        },
        {
          value: 'client',
          text: 'Client'
        },
        {
          value: 'delais_paiement',
          text: 'Délais du paiement'
        }
      ],
      facturesReglement: []
    };
  },
  filters: {
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY');
      }
    },
    formateValue: value => {
      if (value && value != null && value != '') {
        return parseFloat(value)?.toFixed(2);
      }
      return value;
    },
    numberWithSpaces: x => {
      if (x != null && x != undefined) {
        if (Math.sign(x) === -1) {
          var y = Math.abs(x);
          var parts = y.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          return '-' + parts.join('.');
        } else {
          var parts = x.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          return parts.join('.');
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'clientsReglement',
      'TypeReglement',
      'ConditionPaiement',
      'soldeClient',
      'soldeEcheance',
      'getUserData',
      'getAmountNonLettrerReglemnt',
      'getConditionAmountNonLettre',
      'formateDateGetters'
    ]),
    computedReste() {
      let result = 0;
      let data = this.facturesReglement
        .filter(i => i.check == true)
        .map(i => {
          return parseFloat(i.reste);
        });
      if (data.length) {
        result = data.reduce((sum, i) => sum + i);
      }
      // montant_a_regle
      return result.toFixed(2);
    },
    computedSoldeAPaye() {
      let result = 0;
      let data = this.facturesReglement
        .filter(i => i.check == true)
        .map(i => {
          return parseFloat(i.montant_a_regle);
        });
      if (data.length) {
        result = data.reduce((sum, i) => sum + i);
      }
      // montant_a_regle
      return result.toFixed(2);
    },
    computedResetModalLcr() {
      return this.resetModalLcr;
    },
    computedPaiementNameSelected() {
      if (this.reglementToAdd && this.reglementToAdd.type_reglement != null) {
        let fund = this.TypeReglement.filter(
          i => i.id == this.reglementToAdd.type_reglement
        );
        return fund && fund.length ? fund[0].name : null;
      }
      return this.reglementToAdd.type_reglement + '';
    },
    computedTypePartenaire() {
      let typePartenaire = [
        { text: 'Client', value: 'Client' },
        { text: 'Fournisseur', value: 'Fournisseur' }
      ];
      return typePartenaire;
    },
    computedUnite() {
      let unite = [
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' }
      ];
      return unite;
    },
    computedClients() {
      return this.clientsReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedTypeReglement() {
      return this.TypeReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedConditionPaiement() {
      return this.ConditionPaiement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    }
  },
  methods: {
    ...mapActions([
      'getAllTypesReglement',
      'getAllConditionsPaiement',
      'getSoldeClient',
      'createReglementInInterfaceFacture',
      'resetMutationsReglements',
      'getDataReglementInInterfaceFacture',
      'getSoldeEcheance',
      'createReglementLcrFacture',
      'getAmountNonLettrer'
    ]),
    showFiles(file) {
      this.reglementToAdd.files = file;
    },
    saveDataForLcr(data) {
      this.dataLcr = data;
    },
    // numberWithSpaces(x) {
    //   if (x != null && x != undefined) {
    //     if (Math.sign(x) === -1) {
    //       var y = Math.abs(x);
    //       var parts = y.toString().split('.');
    //       parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    //       return '-' + parts.join('.');
    //     } else {
    //       var parts = x.toString().split('.');
    //       parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    //       return parts.join('.');
    //     }
    //   }
    // },
    updateMontantRegler(payload) {
      // this.reglementToAdd.reste = parseFloat(this.reglementToAdd.reste);
      this.reglementToAdd.solde = parseFloat(this.reglementToAdd.solde);
      if (parseFloat(payload.value) == 0) {
        payload.row.check = false;
      }
      // if (
      //   parseFloat(this.reglementToAdd.reste) +
      //     parseFloat(payload.row.montant_a_regle) >
      //   parseFloat(payload.value)
      // ) {
      if (
        parseFloat(
          payload.row.resteInitial - parseFloat(payload.value).toFixed(2)
        ) >= 0
      ) {
        let ancien_montant_a_regle = 0;
        ancien_montant_a_regle = payload.row.montant_a_regle;
        payload.row.montant_a_regle = parseFloat(payload.value).toFixed(2);
        payload.row.reste = parseFloat(
          payload.row.resteInitial - parseFloat(payload.value).toFixed(2)
        );
        payload.row.reste = payload.row.reste.toFixed(2);
        payload.row.reste = parseFloat(payload.row.reste);
        // this.reglementToAdd.reste =
        //   this.reglementToAdd.reste + parseFloat(ancien_montant_a_regle);
        // this.reglementToAdd.reste =
        //   this.reglementToAdd.reste - parseFloat(payload.value);
        // this.reglementToAdd.reste = this.reglementToAdd.reste.toFixed(2);
        this.reglementToAdd.solde =
          this.reglementToAdd.solde + parseFloat(ancien_montant_a_regle);
        this.reglementToAdd.solde =
          this.reglementToAdd.solde - parseFloat(payload.value);
        this.reglementToAdd.solde = this.reglementToAdd.solde.toFixed(2);
        this.reglementToAdd.solde = (
          parseFloat(this.reglementToAdd.solde) +
          parseFloat(ancien_montant_a_regle)
        ).toFixed(2);
        this.reglementToAdd.solde =
          parseFloat(this.reglementToAdd.solde) -
          parseFloat(parseFloat(payload.value).toFixed(2));
        this.reglementToAdd.reste = this.computedReste;
      }
      // }
    },
    deleteUploadFile(index) {
      this.reglementToAdd.files.splice(index, 1);
    },
    rowClass(item, type) {
      if (item && item.reste == 0) {
        return 'ligneAlert';
      }
      return 'ligneNormale';
    },
    handleCheckAll() {
      if (this.checkAll == true) {
        let table = this.facturesReglement.filter(item => item.check == false);
        table.map(item => {
          // if (this.reglementToAdd.reste > 0) {
          item.check = true;
          this.checkFacture(item);
          // }
        });
      } else {
        let table = this.facturesReglement.filter(item => item.check == true);
        table.map(item => {
          if (item.check == true) {
            item.check = false;
            this.checkFacture(item);
          }
        });
      }
    },
    checkFacture(item) {
      // this.reglementToAdd.reste = parseFloat(this.reglementToAdd.reste);
      this.reglementToAdd.solde = parseFloat(this.reglementToAdd.solde);
      // if (this.reglementToAdd.reste > 0) {
      if (item.check == true) {
        // if (this.reglementToAdd.reste > item.resteInitial) {
        // this.reglementToAdd.reste =
        //   this.reglementToAdd.reste - parseFloat(item.resteInitial);
        // this.reglementToAdd.reste = this.reglementToAdd.reste.toFixed(2);
        item.reste = 0;
        item.montant_a_regle =
          parseFloat(item.montant_a_regle) + parseFloat(item.resteInitial);
        this.reglementToAdd.solde = (
          parseFloat(this.reglementToAdd.solde) -
          parseFloat(item.montant_a_regle)
        ).toFixed(2);
        this.reglementToAdd.solde = Math.abs(this.reglementToAdd.solde);
        this.reglementToAdd.solde = this.reglementToAdd.solde.toFixed(2);
        // }
        // else {
        //   item.montant_a_regle = this.reglementToAdd.reste;
        //   item.reste =
        //     parseFloat(item.resteInitial) - this.reglementToAdd.reste;
        //   item.reste = item.reste;
        //   // this.reglementToAdd.reste = 0;
        //   this.reglementToAdd.solde =
        //     this.reglementToAdd.solde - parseFloat(item.montant_a_regle);
        //   this.reglementToAdd.solde = Math.abs(this.reglementToAdd.solde);
        // }
      } else {
        // this.reglementToAdd.reste =
        //   this.reglementToAdd.reste + parseFloat(item.montant_a_regle);
        this.reglementToAdd.solde =
          parseFloat(this.reglementToAdd.solde) +
          parseFloat(item.montant_a_regle);

        item.reste = parseFloat(item.resteInitial);
        item.montant_a_regle = parseFloat(item.montant_a_regle_initial);
      }
      // }
      // else {
      //   if (item.check == false) {
      //     this.reglementToAdd.reste =
      //       this.reglementToAdd.reste + parseFloat(item.montant_a_regle);
      //     this.reglementToAdd.solde = (
      //       parseFloat(this.reglementToAdd.solde) +
      //       parseFloat(item.montant_a_regle)
      //     ).toFixed(2);
      //     item.reste = parseFloat(item.resteInitial);
      //     item.montant_a_regle = parseFloat(item.montant_a_regle_initial);
      //   } else {
      //     item.check = false;
      //   }
      // }
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      if (
        this.facturesReglement.filter(i => i.check == true).length ==
        this.facturesReglement.length
      ) {
        this.checkAll = true;
      }
      this.reglementToAdd.reste = this.computedReste;
    },
    resetModal() {
      this.reglementToAdd = {
        filiale_id: null,
        filiale_nom: null,
        type_reglement: null,
        type_partenaire: 'client',
        condition_paiement: null,
        montant_a_payer: null,
        unite: 'EUR',
        ref: null,
        date_paiement: moment(Date.now()).format('DD-MM-YYYY'),
        reste: null,
        solde: null,
        note: null
      };
      this.facturesReglement = [];
      this.show = false;
      this.loading = false;
      this.vendeur = '';
    },
    hideModal() {
      this.dialog = false;
      this.resetModal();
      this.resetMutationsReglements();
    },
    calculRestePayer() {
      this.reglementToAdd.reste = this.reglementToAdd.montant_a_payer;
      let TableF = this.facturesReglement?.filter(item => item.check == false);
      if (TableF.length != this.facturesReglement.length) {
        // this.getFacturesFiliales();
      }
    },
    async getFacturesFiliales() {
      this.resetModalLcr = !this.resetModalLcr;
      this.dialog = true;
      let filiale_list = this.getUserData.filiales.map(item => item.id);
      if (filiale_list.includes(this.dataToUse[0].client_id)) {
        this.reglementToAdd.type_partenaire =
          this.interfaceName == 'oblige'
            ? this.dataToUse[0].typeReglement
            : 'Fournisseur';
        this.reglementToAdd.filiale_id = this.dataToUse[0].vendeur_id;
        this.reglementToAdd.filiale_nom = this.dataToUse[0].vendeur;
      } else {
        this.reglementToAdd.type_partenaire =
          this.interfaceName == 'oblige'
            ? this.dataToUse[0].typeReglement
            : 'Client';
        this.reglementToAdd.filiale_id = this.dataToUse[0].client_id;
        this.reglementToAdd.filiale_nom = this.dataToUse[0].client;
      }

      this.facturesReglement = this.dataToUse.map(item => {
        if (this.interfaceModal == 'ReglementFacture') {
          return ReglementFacture.create(item);
        } else if (this.interfaceModal == 'ReglementFactureRowLibre') {
          return ReglementFactureRowLibre.create(item);
        } else if (this.interfaceModal == 'ReglementFactureRowMaster') {
          return ReglementFactureRowMaster.create(item);
        } else if (this.interfaceModal == 'ReglementFactureRowOblige') {
          return ReglementFactureRowOblige.create(item);
        }
      });
      let somme_rest = 0;
      this.facturesReglement.forEach(element => {
        somme_rest = somme_rest + element.resteInitial;
      });

      this.reglementToAdd.montant_a_payer = parseFloat(somme_rest)?.toFixed(2);

      this.reglementToAdd.reste = 0;
      this.checkAll = true;
      this.show = true;
      const responseData = await this.getDataReglementInInterfaceFacture(
        this.reglementToAdd.filiale_id
      );
      if (responseData.succes) {
        this.reglementToAdd.condition_paiement =
          responseData.data.payment_condition_id;
        this.reglementToAdd.type_reglement = responseData.data.payment_type_id;
      }
      const responseE = await this.getSoldeEcheance({
        id: this.reglementToAdd.filiale_id,
        type_partenaire: this.reglementToAdd.type_partenaire,
        id_client:
          this.societeRequired == true ? this.dataToUse[0].vendeur_id : null
      });
      if (responseE) {
        this.reglementToAdd.solde_echeance = this.soldeEcheance;
      }
      this.getAmountNonLettrer({
        filiale_id: this.reglementToAdd.filiale_id,
        type_partenaire: this.reglementToAdd.type_partenaire,
        id_client:
          this.societeRequired == true ? this.dataToUse[0].vendeur_id : null
      });
      const response = await this.getSoldeClient({
        id: this.reglementToAdd.filiale_id,
        type_partenaire: this.reglementToAdd.type_partenaire,
        id_client:
          this.societeRequired == true ? this.dataToUse[0].vendeur_id : null
      });
      if (response) {
        this.reglementToAdd.solde = this.soldeClient;
        this.reglementToAdd.solde = parseFloat(
          this.reglementToAdd.solde - parseFloat(somme_rest)?.toFixed(2)
        ).toFixed(2);
        this.reglementToAdd.solde_init = this.soldeClient;
        this.show = false;
      }
    },
    async handleSubmitAddReglement() {
      if (this.$refs.formRegl.validate()) {
        if (this.reglementToAdd.montant_a_payer == 0) {
          this.error = 'If faut avoir le montant à payer supèrieur à 0';
        } else {
          this.loading = true;
          let bodyFormData = new FormData();
          bodyFormData.append('balance', this.reglementToAdd.reste);
          bodyFormData.append('amount', this.reglementToAdd.montant_a_payer);
          bodyFormData.append('currency', this.reglementToAdd.unite);
          bodyFormData.append(
            'payment_date',
            this.reglementToAdd.date_paiement
          );
          bodyFormData.append('type_id', this.reglementToAdd.type_reglement);
          if (!this.computedPaiementNameSelected.includes('LCR')) {
            bodyFormData.append(
              'payment_condition_id',
              this.reglementToAdd.condition_paiement
            );
          }
          bodyFormData.append('filiale_id', this.reglementToAdd.filiale_id);
          bodyFormData.append('payment_note', this.reglementToAdd.note);
          bodyFormData.append('reference', this.reglementToAdd.ref);
          bodyFormData.append(
            'type_partenaire',
            this.reglementToAdd.type_partenaire
          );

          for (let i = 0; i < this.facturesReglement.length; i++) {
            if (this.facturesReglement[i].check == true) {
              bodyFormData.append(
                'factures[' + i + '][id]',
                this.facturesReglement[i].id
              );
              bodyFormData.append(
                'factures[' + i + '][montant_regle]',
                this.facturesReglement[i].montant_a_regle
              );
              bodyFormData.append(
                'factures[' + i + '][total_ttc]',
                this.facturesReglement[i].total_ttc
              );
            }
          }
          for (let i = 0; i < this.reglementToAdd.files?.length; i++) {
            bodyFormData.append(
              'files[' + i + '][file]',
              this.reglementToAdd.files[i]
            );
            bodyFormData.append(
              'files_descriptions[' + i + ']',
              this.reglementToAdd.files[i]['description']
            );
          }

          const response = await this.createReglementInInterfaceFacture(
            bodyFormData
          );
          if (response.succes) {
            let indexE;
            this.dataToUse.forEach(element => {
              if (this.interfaceModal == 'ReglementFactureRowMaster') {
                indexE = response.data.factures_regles.findIndex(
                  i => i.id == element.id_facture
                );
              } else {
                indexE = response.data.factures_regles.findIndex(
                  i => i.id == element.id
                );
              }

              if (indexE >= 0) {
                element.updateDataReglement(
                  response.data.factures_regles[indexE]
                );
              }
            });
            this.$emit('updateDataInParentComponentReglement', {
              response: response.data,
              data: this.facturesReglement.filter(i => i.check == true)
            });
            if (
              this.reglementToAdd.type_reglement != null &&
              this.computedPaiementNameSelected.includes('LCR') &&
              this.dataLcr != null &&
              this.facturesReglement.filter(i => i.check == true).length > 0
            ) {
              let bodyFormDataEch = new FormData();

              this.dataLcr.lcr.forEach((element, i) => {
                bodyFormDataEch.append(
                  'echeances[' + i + '][libelle]',
                  element.libelle
                );
                bodyFormDataEch.append(
                  'echeances[' + i + '][client_id]',
                  this.dataLcr.client_id
                );
                bodyFormDataEch.append(
                  'echeances[' + i + '][date_echeance]',
                  element.date
                );
                bodyFormDataEch.append(
                  'echeances[' + i + '][beneficiaire_id]',
                  this.dataLcr.beneficiaire_id
                );
                bodyFormDataEch.append(
                  'echeances[' + i + '][valeur_en]',
                  this.dataLcr.valeur_en
                );
                bodyFormDataEch.append(
                  'echeances[' + i + '][date_envoi]',
                  null
                );
                bodyFormDataEch.append(
                  'echeances[' + i + '][date_signature]',
                  null
                );
                bodyFormDataEch.append(
                  'echeances[' + i + '][date_valeur]',
                  null
                );
                bodyFormDataEch.append(
                  'echeances[' + i + '][devise]',
                  this.dataLcr.devise
                );
                bodyFormDataEch.append(
                  'echeances[' + i + '][amount]',
                  element.value
                );
                bodyFormDataEch.append(
                  'echeances[' + i + '][rib_id]',
                  this.dataLcr.rib.id
                );
              });
              const resEch = await this.createReglementLcrFacture({
                id: response.data.id,
                data: bodyFormDataEch
              });
              if (resEch) {
                this.resetModalLcr = !this.resetModalLcr;
              }
            }
            this.loading = false;
            this.hideModal();
          } else {
            this.loading = false;
          }
        }
      }
    }
  },
  components: {
    EditableInput: () => import('@/views/component/EditableInput.vue'),
    Lcr,
    badge: () => import('@/views/component/badge.vue'),
    IconReglementFacture: () => import('@/assets/img/IconReglementFacture.vue'),
    PieceJointe: () => import('@/views/component/Facture/PieceJointe.vue')
  },
  mounted() {
    this.getAllTypesReglement();
    this.getAllConditionsPaiement();
  }
};
</script>

<style lang="scss">
.block-icon {
  background-color: #f1edfb !important;
  box-shadow: none !important;
  padding: 6.5px;
  font-size: 11px;
  font-weight: 600;
  color: #704ad1;
  border-radius: 4px;
  padding: 6px 5px;
  border: 1px solid #704ad1;
  box-shadow: 5px 6px 8px #9799d67a;
  cursor: pointer;
  height: 32px !important;
  width: 32px !important;
  min-width: 30px !important;
  z-index: 1;
  svg {
    fill: #704ad1;
    overflow: hidden;
    vertical-align: middle;
    transform: rotateZ(0deg);
    height: 20px;
    width: 20px;
  }
}
.tabs-add-reglement-fac {
  width: 100%;
  .tab-add-reglement-fac {
    width: 50%;
    // margin: auto !important;
    // &:hover {
    // height: 35px;
    // }
  }
  .v-slide-group__content {
    justify-content: space-evenly;
    display: flex;
  }
}
.date-paiement-fac {
  .v-input__prepend-inner {
    margin-top: 14px !important;
  }
}
</style>
